// router.js or router/index.js
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/welcome',
        name: 'WelCome',
        component: () => import('@/components/welcome.vue'),
    },
    {
        path: '/',
        name: 'ChatGPT',
        component: () => import('@/components/ChatGPT.vue'),
    },
    {
        path: '/service',
        name: 'SerVice',
        component: () => import('@/components/service.vue'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/components/contact.vue'),
    },
    {
        path: '/feedback',
        name: 'FeedBack',
        component: () => import('@/components/feedback.vue'),
    },
    // Add more routes as needed
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
