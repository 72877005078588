import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import locale from 'element-plus/es/locale/lang/zh-cn'

import * as Icons from '@element-plus/icons-vue'


const hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks');
const viewportUnitsBuggyfill = require('viewport-units-buggyfill');
viewportUnitsBuggyfill.init({
    hacks,
});

/**
 * 
 刷新
router.afterEach((to, from) => {
    require('viewport-units-buggyfill').refresh();
});
 * 
 */

const app = createApp(App)

for (let i in Icons) {
    app.component(i, Icons[i])
}

//const apiUri = 'http://localhost:8080/api'

app.use(router)
app.use(ElementPlus, { locale })

app.mount('#app')
