<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <router-view></router-view>
</template>

<script>
import { onBeforeMount } from 'vue';
// import ChatGPT from './components/ChatGPT.vue'
// import welcome from './components/welcome.vue'
import Cookies from 'js-cookie'
var code = undefined
export default {
  name: 'App',
  setup() {
    const serverUrl = window.serverUrl//'http://localhost:8080/api'
    const method = {
      login() {
        //自动登录获取token
        /*const auth = new WeChatAuth({
          appId: 'wx5bd889151affd87e', // 替换为自己的 App ID
          scope: ['snsapi_base'], // 设置授权作用域
          redirectUri: window.location.href // 重定向 URI（当前页面）
        });*/

        // 调用微信登录接口
        // 发送 code 到服务器进行校验和获取 OpenID
        const url = `${serverUrl}/takeout/commonbase/verify?code=${this.code}`
        fetch(url)
          .then(response => response.json())
          .then(data => {
            if (data && data.code == 0) {
              //过期时间是半天
              Cookies.set('token', data.data.token, { expires: 0.5 })
            } else {
              console.error('Failed to login');
            }
          })
          .catch(err => {
            console.error('Error occurred while getting OpenID: ', err);
          });
      },
      getCode() {
        if (!Cookies.get('token')) {
          // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
          var code = this.getUrlParam('code')
          const local = window.location.href
          if (code == null || code === '') {
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx32f3098d9060e158&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
          } else {
            //把code传给后台获取用户信息
            this.code = code;
            this.login()
          }
        }
      },
      getUrlParam: function (name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        var r = window.location.search.substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
      },
    }

    onBeforeMount(() => {
      method.getCode()
      // if (!Cookies.get('token')) {
      //   Cookies.set('token', '77fec7fcbbf861ca20b784829b751ef6', { expires: 0.5 })
      // }
    })
    return {
      method,
      code,
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
